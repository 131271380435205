import React, { Component } from 'react';

export default class WishlistPage extends Component {

  static propTypes = {
  }

  render() {
    return (
      <div className="wishlist">
        <div className="container mt-10 grid-720">
          <script src="https://gist.github.com/hwkr/9b67de673a4a353a448afd3a4fea580d.js" />
        </div>
      </div>
    );
  }
}

